@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

body {
  font-family: "Inter", sans-serif;
  background-color: #030712;
  color: #ffffff;
}

.container {
  width: 100%;
  max-width: 1275px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.headerList .active {
  border-bottom: 1px solid;
}

.footerList .active {
  border-bottom: 2px solid;
  font-size: 12px;
  color: #ffffff;
}
